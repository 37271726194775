

























































































































































































































































import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep } from 'lodash';
import { VueMaskFilter } from 'v-mask';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';
import ContactService from '@/services/crm/contact.service';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ContactModel from '@/models/crm/contact.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import DatePickerField from '@/components/date-picker-field.vue';
import ConversationModel from '@/models/crm/conversation.model';
import ClientModel from '@/models/crm/client.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ClientService from '@/services/crm/client.service';
import ProspectModel from '@/models/crm/prospect.model';
import ProspectService from '@/services/crm/prospect.service';
import ConversationService from '@/services/crm/conversation.service';
import ContentDialog from '@/components/content-dialog.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import CrmChatProspectForm from '@/components/crm/chat-prospect-form.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { Translations } from '@/utils/phone-field-plugin/translations';
import CrmExistingContactsView from './existing-contacts-view.vue';

interface IChatContactFormModel {
  conversationType: ConversationTypeEnum;
  client: ClientModel | null;
  prospect: ProspectModel | null;
  contact: ContactModel;
}

@Component({
  components: {
    Field,
    DatePickerField,
    ContentDialog,
    CrmChatProspectForm,
    VuePhoneNumberInput,
    CrmExistingContactsView,
  },
})
export default class CrmChatContactForm extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @VModel()
  hasExistingContacts!: boolean;

  @Prop({ required: true })
  conversation!: ConversationModel;

  @Prop()
  disableForm!: boolean;

  existingContacts: ContactModel[] = [];

  existingTelephones: ContactModel[] = [];

  formIsValid = true;

  settings: SettingsModel | null = null;

  translations: unknown = Translations.getTranslations();

  translationsWhatsapp: unknown = Translations.getTranslationsWhatsApp();

  preferredCountries: string[] = ['BR'];

  model: IChatContactFormModel = {
    conversationType: ConversationTypeEnum.Client,
    client: null,
    prospect: null,
    contact: plainToClass(ContactModel, {
      id: null,
      nome: null,
      tiposContato: null,
      email: null,
      telefone: null,
      whatsapp: null,
      dataAniversario: null,
      flagAtivo: 1,
    }),
  };

  rules = {
    tiposContato: [ValidationRules.required],
    conversationType: [ValidationRules.required],
    nome: [ValidationRules.required],
    email: [ValidationRules.email],
    telefone: [ValidationRules.phone],
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    prospect: {
      show: false,
    },
    existingContacts: {
      show: false,
    },
    existingTelephones: {
      show: false,
    },
  };

  contactTypeOptions: ContactTypeModel[] = [];

  telephoneMaskActive = false;

  searchClient = '';

  searching = false;

  clientItems: ClientModel[] = [];

  searchProspect = '';

  prospectItems: ProspectModel[] = [];

  disableTelephone = false;

  disableWhatsapp = false;

  isWhatsAppValid = false;

  whatsAppCountryCallingCode!: string;

  private readonly debounce = 450;

  private debounceId!: number;

  telephone = '';

  telephoneAcceptedDuplicate = '';

  whatsapp = '';

  acceptedWhatsapp = '';

  clickedFromSave = false;

  telephoneValid = true;

  internalDisableForm = false;

  onChangeWhatsApp(e): void {
    this.isWhatsAppValid = e.isValid;
    this.whatsAppCountryCallingCode = e.countryCallingCode;
  }

  @Watch('searchClient')
  watchSearchClient(value: string): void {
    clearTimeout(this.debounceId);
    if (!value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.clientItems = [];
      this.searching = false;
      return;
    }

    let unmaskedValue = value;

    if (value.match(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/)) {
      unmaskedValue = value.replace(/[^0-9]+/g, '');
    }

    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const result = await this.clientService.quickSearchIgsClient(unmaskedValue);
        this.clientItems = result.map((item) => {
          const newItem = item;
          newItem.nomeFantasia = newItem.nomeFantasia || newItem.nome;
          return newItem;
        });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  @Watch('searchProspect')
  watchSearchProspect(value: string): void {
    clearTimeout(this.debounceId);
    if (!value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.prospectItems = [];
      this.searching = false;
      return;
    }

    let unmaskedValue = value;

    if (value.match(/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/)) {
      unmaskedValue = value.replace(/[^0-9]+/g, '');
    }

    this.searching = true;
    this.debounceId = setTimeout(async () => {
      try {
        const result = await this.prospectService.quickSearch(unmaskedValue, true);

        this.prospectItems = result
          .filter((item) => !item.flagExcluido)
          .map((item) => {
            const newItem = item;
            newItem.fantasia = newItem.fantasia || newItem.razaoSocial || newItem.contato;
            return newItem;
          });
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searching = false;
      }
    }, this.debounce);
  }

  @Watch('model.conversationType')
  watchConversationType(): void {
    this.telephoneAcceptedDuplicate = '';
  }

  async mounted(): Promise<void> {
    if (!this.conversation.tipo || (this.conversation.tipo && this.conversation.tipo.toString() === 'UNDEFINED')) {
      this.model.conversationType = ConversationTypeEnum.Client;
    } else {
      this.model.conversationType = this.conversation.tipo;
    }

    this.internalDisableForm = this.disableForm;

    const loader = this.setBusyLoader();
    try {
      const tasks = [this.loadSettings(), this.loadContactTypes()];

      if (this.conversation.contato?.id) {
        tasks.push(this.loadContact(this.conversation.contato.id));
      }

      if (this.conversation.tipo === ConversationTypeEnum.Client && this.conversation.cnpj) {
        tasks.push(this.loadClient(this.conversation.cnpj));
      }

      if (this.conversation.tipo === ConversationTypeEnum.Prospect && this.conversation.prospect) {
        tasks.push(this.loadProspect(this.conversation.prospect.codProspect));
      }

      await Promise.all(tasks);

      if (!this.conversation.contato?.id || !this.model.contact.whatsapp) {
        this.model.contact.whatsapp = `+${this.conversation.numeroWhatsapp}`;
        this.whatsapp = this.model.contact.whatsapp;
      }

      this.disableTelephone = !!this.model.contact?.telefone;
      this.disableWhatsapp = !!this.model.contact?.whatsapp;

      // If a client or prospect was provided, try to get a contact
      if (this.model.client) {
        this.clientItems = [this.model.client];
        await this.onSelectClient();
      }

      if (this.model.prospect) {
        this.prospectItems = [this.model.prospect];
        await this.onSelectProspect();
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSelectClient(): Promise<void> {
    if (this.model.client && this.model.contact.whatsapp) {
      const contact = await this.contactService.getContactByClientId(
        this.model.contact.whatsapp,
        this.model.client.cnpjCpf,
        ClientTypeEnum.Client,
        this.settings?.flagPermiteClientesSituacao99Crm360,
      );

      if (contact) {
        this.model.contact = contact;
        this.disableTelephone = !!this.model.contact?.telefone;
        this.disableWhatsapp = !!this.model.contact?.whatsapp;
      }
    }

    this.telephoneAcceptedDuplicate = '';
  }

  async onSelectProspect(): Promise<void> {
    if (this.model.prospect && this.model.contact.whatsapp) {
      const contact = await this.contactService.getContactByClientId(
        this.model.contact.whatsapp,
        this.model.prospect.codProspect,
        ClientTypeEnum.Prospect,
        this.settings?.flagPermiteClientesSituacao99Crm360,
      );

      if (contact) {
        this.model.contact = contact;
        this.disableTelephone = !!this.model.contact?.telefone;
        this.disableWhatsapp = !!this.model.contact?.whatsapp;
      }
    }
    this.telephoneAcceptedDuplicate = '';
  }

  async onVerifyContact(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      this.hasExistingContacts = false;
      if (this.model.contact.id && !!this.model.contact?.whatsapp) {
        this.isWhatsAppValid = true;
        this.onSave();
        return;
      }

      if (!this.model.contact.id) {
        if (this.whatsAppCountryCallingCode
            && !this.model.contact.whatsapp.startsWith(this.whatsAppCountryCallingCode)) {
          this.model.contact.whatsapp = `+${this.whatsAppCountryCallingCode} ${this.model.contact.whatsapp}`;
        } else if (this.model.contact.whatsapp) {
          this.model.contact.whatsapp = `+${this.model.contact.whatsapp}`;
        }
      }

      if (this.acceptedWhatsapp !== ''
        && (this.acceptedWhatsapp === this.whatsapp || this.acceptedWhatsapp === this.model.contact.whatsapp)) {
        this.isWhatsAppValid = true;
        this.onSave();
        return;
      }

      let clientId!: string;
      if (this.model.conversationType === ConversationTypeEnum.Prospect && this.model.prospect) {
        clientId = this.model.prospect.codProspect;
      } else if (this.model.conversationType === ConversationTypeEnum.Client && this.model.client) {
        clientId = this.model.client.cnpjCpf.replace(/\D/g, '');
      }

      try {
        this.existingContacts = await this.contactService.verifyWaIdContacts(
          this.model.contact.id,
          this.model.contact.whatsapp,
          this.model.conversationType === ConversationTypeEnum.Prospect
            ? ClientTypeEnum.Prospect : ClientTypeEnum.Client,
          clientId,
        );

        if (this.existingContacts.length === 0) {
          this.onSave();
        } else if (!this.dialogConfig.existingTelephones.show && !this.dialogConfig.existingContacts.show) {
          this.toggleDialogExistingContacts();
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      }
    }
  }

  async onVerifyTelephone(): Promise<void> {
    this.hasExistingContacts = false;
    if (this.isTelephoneBlank) {
      this.telephoneValid = true;
      return;
    }

    if (this.disableTelephone) {
      this.telephoneValid = true;
      if (this.clickedFromSave) {
        this.telephoneAcceptedDuplicate = this.telephone;
        this.onSave();
      }
      return;
    }

    let clientId!: string;
    if (this.model.conversationType === ConversationTypeEnum.Prospect && this.model?.prospect !== null) {
      clientId = this.model.prospect.clientModel.prospectId
        ? this.model.prospect.clientModel.prospectId.toString() : '';
    } else if (this.model.conversationType === ConversationTypeEnum.Client && this.model.client !== null) {
      clientId = this.model.client.cnpjCpf.replace(/\D/g, '');
    }

    try {
      this.existingTelephones = await this.contactService.verifyWaIdContacts(
        null,
        this.telephone,
        this.model.conversationType === ConversationTypeEnum.Prospect ? ClientTypeEnum.Prospect : ClientTypeEnum.Client,
        clientId,
        false,
        true,
      );

      if (this.existingTelephones.length !== 0) {
        if (!this.dialogConfig.existingTelephones.show && !this.dialogConfig.existingContacts.show) {
          this.toggleDialogExistingTelephones();
        }
      } else if (this.clickedFromSave) {
        this.telephoneValid = true;
        this.clickedFromSave = false;
        this.telephoneAcceptedDuplicate = this.telephone;
        this.onSave();
      }
    } catch (err) {
      this.telephone = '';
      this.telephoneValid = false;
      this.$notify.error(err && (err as Error).message);
    }
  }

  onCancelSave(): void {
    this.toggleDialogExistingContacts();
    this.onCancel();
  }

  onCancelSaveTelephone(): void {
    this.telephone = '';
    this.toggleDialogExistingTelephones();
  }

  toggleDialogExistingContacts(): void {
    this.dialogConfig.existingContacts.show = !this.dialogConfig.existingContacts.show;
    this.hasExistingContacts = !this.hasExistingContacts;
  }

  toggleDialogExistingTelephones(): void {
    this.dialogConfig.existingTelephones.show = !this.dialogConfig.existingTelephones.show;
    this.hasExistingContacts = !this.hasExistingContacts;
  }

  async onSave(): Promise<void> {
    if (this.telephone
      && this.telephone !== this.telephoneAcceptedDuplicate) {
      this.clickedFromSave = true;
      await this.onVerifyTelephone();
    } else if (FormHelper.validate(this.$refs.form as Vue)) {
      if (!this.isWhatsAppValid) {
        this.$notify.error(`${this.$t('global.validations.phone', { fieldName: 'Whatsapp' })}`);
        return;
      }

      if (this.isTelephoneBlank) {
        this.telephoneValid = true;
      }

      if (!this.telephoneValid) {
        this.$notify.error(`${this.$t('global.validations.phone', { fieldName: 'Telefone' })}`);
        return;
      }
      if (this.model.conversationType === ConversationTypeEnum.Client && !this.model.client?.id) {
        this.$notify.error(this.$t('crm.chatContactForm.messages.selectAClientToSaveContact'));
        return;
      }

      if (this.model.conversationType === ConversationTypeEnum.Prospect && !this.model.prospect?.codProspect) {
        this.$notify.error(this.$t('crm.chatContactForm.messages.selectAProspectToSaveContact'));
        return;
      }

      const conversationSnapshot = cloneDeep(this.conversation);

      const loader = this.setBusyLoader();
      try {
        let cnpj = '';
        let idProspect: number | null = null;
        if (this.model.conversationType === ConversationTypeEnum.Prospect) {
          cnpj = this.model.prospect?.cnpj as string;
          idProspect = parseInt(this.model.prospect?.codProspect || '0', 10);
          this.model.client = null;
        } else {
          cnpj = this.model.client?.cnpjCpf as string;
          this.model.prospect = null;
        }

        this.model.contact.idProspect = idProspect as number;

        if (this.whatsAppCountryCallingCode) {
          this.model.contact.whatsapp = `+${this.whatsAppCountryCallingCode} ${this.model.contact.whatsapp}`;
        }
        this.model.contact.telefone = this.telephone;

        const contactModel = cloneDeep(this.model.contact);
        contactModel.cnpj = (cnpj || '').replace(/\D/g, '');

        let result: ContactModel;
        if (this.model.contact.id) {
          result = await this.contactService.update(contactModel);
        } else {
          result = await this.contactService.create(contactModel);
        }

        this.conversation.contato = result;
        this.conversation.tipo = this.model.conversationType;
        this.conversation.cnpj = cnpj;
        this.conversation.prospect = this.model.prospect as ProspectModel;
        this.conversation.cliente = this.model.client as ClientModel;

        await this.conversationService.attachContact(this.conversation);

        this.$notify.success(this.$t('crm.chatContactForm.successfullySave'));

        this.$emit('complete', this.conversation);
      } catch (err) {
        this.conversation = conversationSnapshot;

        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onCreateProspect(): void {
    this.dialogConfig.prospect.show = true;
  }

  onAfterSaveProspect(prospect: ProspectModel): void {
    this.prospectItems = [prospect];
    this.model.prospect = prospect;
    this.internalDisableForm = true;
  }

  onProspectDialogClose(): void {
    this.dialogConfig.prospect.show = false;
  }

  get isFormBlocked(): boolean {
    return !!this.model.contact?.id;
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.model.contact.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  // eslint-disable-next-line class-methods-use-this
  public cnpjCpfFilter(value: string): string {
    let cnpjcpf = value;
    if (cnpjcpf && (cnpjcpf.includes('/') || cnpjcpf.includes('-'))) {
      cnpjcpf = value.replace(/\D/g, '');
    }
    const mask = cnpjcpf && cnpjcpf.trim().length > 11 ? '##.###.###/####-##' : '###.###.###-##';
    return VueMaskFilter(cnpjcpf, mask);
  }

  private async loadContact(id: number): Promise<void> {
    const result = await this.contactService.getContact(id);
    const mappedObject = ObjectHelper.mapObjectValues<ContactModel>(result, {
      ignore: ['flagMethod', 'inclusaoUsuarioId', 'inclusaoData', 'alteracaoUsuarioId', 'alteracaoData', 'tipo'],
    });
    this.model.contact = plainToClass(ContactModel, mappedObject);
    this.telephone = this.model.contact.telefone;
  }

  private async loadClient(clientId: string): Promise<void> {
    const client = await this.clientService.getIgsClient(clientId);
    if (client !== null) {
      client.nomeFantasia = client.nomeFantasia || client.nome;
    }

    this.model.client = client;
  }

  private async loadProspect(clientId: string): Promise<void> {
    this.model.prospect = await this.prospectService.get(Number(clientId));
  }

  private async loadSettings(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
  }

  private async loadContactTypes(): Promise<void> {
    this.contactTypeOptions = await this.contactService.getContactTypes();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.chatContactFormContainer,
      canCancel: false,
    });
  }

  get isTelephoneBlank(): boolean {
    return this.telephone === null || this.telephone === '';
  }

  get clientToDuplicateContactsView(): ClientModel | null {
    if (this.model.conversationType === ConversationTypeEnum.Prospect && this.model.prospect !== null) {
      return this.model.prospect.clientModel;
    }
    return this.model.client;
  }

  public onChangeTipoContato(event): void {
    if (event instanceof Array && event.length === 0) {
      this.model.contact.tiposContato = null;
    }
  }
}
